import styled from '@emotion/styled';

export const TabsSliderWrapper = styled.div`
  @media screen and (max-width: 991px) {
    
  }
`

export const Slide = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    display: block;
  }
`

export const Img = styled.img`
  width: 175px;
  flex: 0 0 175px;
  height: auto;
  margin-right: 30px;
  align-self: flex-start;
  @media screen and (max-width: 991px) {
    
  }
`

export const Title = styled.p`
  color: rgb(99, 85, 45);
  font-size: 20px;
  margin-bottom: 15px;
  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }
`

export const Subtitle = styled.p`
  margin-bottom: 14px;
  font-weight: 700;
  font-style: italic;
  color: #000;
  @media screen and (max-width: 991px) {
    
  }
`

export const Content = styled.div`

  @media screen and (max-width: 991px) {
    
  }
`

export const Texts = styled.div`

  @media screen and (max-width: 991px) {
    
  }
`

export const Text = styled.p`
  color: #000;
  &:not(:last-child) {
    margin-bottom: 14px;
  }
  @media screen and (max-width: 991px) {
    
  }
`

export const Navigation = styled.div`
  position: absolute;
  top: 50%;
  left:0;
  right: 0;
  transform: translateY(-50%);

  display: flex;
  justify-content: space-between;

  z-index: 100;
  pointer-events: none;
  padding: 0 10px;

  button {
    display: flex;
    justify-content: center;
    border-radius: 50%;
    pointer-events: all;
    transition: all .3s ease;
  }
`