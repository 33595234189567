import TabsSlider from '@/blocks/TabsSlider';
import styled from '@emotion/styled';
import { motion } from "framer-motion"
import { useState } from 'react';
import { Map, MapContent, MapIframe, MapText, MapTitle, MapTop } from './styled';

const Main = styled.div`
`

const Wrapper = styled.div`
  margin-top: 15px;
`

const TabsStyled = styled.div`
  display: flex;
  gap: 20px;
  margin: 0 15px;
  + div {
    position: relative;
    top: -3px;
    margin-bottom: -3px;
  }
  @media screen and (max-width: 768px) {
    gap: 10px;
  }
`

const tabVariants = {
  // TODO: Закончил на бордерах
  inactive: {
    background: 'linear-gradient(180deg, rgba(187, 187, 187, 0.2) 76.05%, rgba(73, 73, 73, 0.2) 100%), linear-gradient(180deg, #BBBBBB 0%, #494949 125%)'
  },
  active: {
    background: 'linear-gradient(180deg, rgba(236, 226, 204, 0.2) 76.05%, rgba(94, 91, 80, 0.2) 100%), linear-gradient(180deg, #ECE2CC 0%, #5E5B50 125%)'
  }
  // inactive: {
  //   backgroundImage: 'url("../tab-inactive.png")' 
  // },
  // active: {
  //   backgroundImage: 'url("../tab-active.png")'
  // }
}

const Tab = styled(motion.button)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 50%;
  height: 38px;

  font-family: "GeorgiaDev";
  text-shadow: 0.707px 0.707px 0px rgb(255 255 255);
  font-weight: bold;

  color: #000;
  box-shadow: inset 0px -10px 7px 0px rgb(0 0 0 / 20%);

  border-style: solid;
  border-width: 1px;
  border-color: rgb(252, 234, 172);
  border-radius: 14px 14px 0 0;
  border-bottom-width: 0;
`

const Contents = styled.div`
`

const Content = styled.div`
  height: 360px;
  padding: 50px 40px 80px 40px;
  @media screen and (max-width: 991px) {
    height: auto;
  }
  @media screen and (max-width: 768px) {
    padding: 25px 40px;
  }
`

const CustomBorderWrapper = styled.div`
  position: relative;
  z-index: 10;
  top: -5px;
`


export default function Tabs() {
  const [selected, setSelected] = useState(1);

  const onSelectTabHandler = (e) => {
    const id = Number(e.target.getAttribute('data-id'))
    setSelected(id)
  }

  return (
    <Main>
      <Wrapper className='container'>
        <TabsStyled>
          <Tab onClick={onSelectTabHandler} data-id={1} animate={selected === 1 ? "active" : "inactive"} variants={tabVariants}>Приветствуем</Tab>
          <Tab onClick={onSelectTabHandler} data-id={2} animate={selected === 2 ? "active" : "inactive"} variants={tabVariants}>Мы на карте</Tab>
        </TabsStyled>
        <CustomBorderWrapper className='billet'>
          <Contents>
            {selected === 1 && <Content>
              <TabsSlider />
            </Content>}
            {selected === 2 && <Content>
              <Map>
                <MapTop>Мы обеспечиваем нашим клиентам политику открытых дверей. Пожалуйста, обращайтесь к нам 24 часа в сутки.</MapTop>
                <MapContent>
                  <MapTitle>ООО «Ритуальная служба»</MapTitle>
                  <MapText>
                    <p>г. Красноярск 660028 <br />
                      Телевизорная 1, стр.10
                    </p>
                    <br />
                    <div style={{ display: 'flex' }}>
                      <p style={{ marginRight: 5 }}>Телефоны:</p>
                      <p>
                        <a href="tel:2560540">2560540</a> <br />
                        <a href="tel:8902926970">8902926970</a>
                      </p>
                    </div>
                    <br />
                    <p>Email: <a href="mailto:ritualnaya24@gmail.com">ritualnaya24@gmail.com</a></p>
                  </MapText>
                </MapContent>
                <MapIframe>
                  <iframe
                    loading='lazy'
                    src="https://yandex.ru/map-widget/v1/?um=constructor%3A14f4aae33d8a9aae945742fa0ef96fe57a6b3b2e7b99ad4c7092f0f36aa0ede2&amp;source=constructor"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                  />
                </MapIframe>
              </Map>
            </Content>}
          </Contents>
        </CustomBorderWrapper>
      </Wrapper>
    </Main>
  )
}


