import styled from '@emotion/styled';

export const Map = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  color: #000;
  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
`

export const MapTop = styled.div`
  flex: 1 1 100%;
  margin-bottom: 20px;
  font-size: 14px;
`

export const MapContent = styled.div`
  @media screen and (max-width: 991px) {
    margin-bottom: 20px;
  }
`

export const MapTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #63552d;
`

export const MapText = styled.div`
  p {}
  a {
    color: #000;
    &:hover {
      text-decoration: none;
    }
  }
  b {}
`

export const MapIframe = styled.div`
  background-color: #fff;
  height: 222px;
  flex: 0 0 520px;
  margin-left: auto;
  border: 2px solid rgb(154, 154, 154);
  @media screen and (max-width: 991px) {
    margin-left: 0;
    flex: auto;
  }
`