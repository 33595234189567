import { useRef } from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Content, Img, Navigation, Slide, Subtitle, TabsSliderWrapper, Text, Texts, Title } from "./styled";

export default function TabsSlider() {

  const swiperRef = useRef()

  return (
    <TabsSliderWrapper>
      <Swiper
        ref={swiperRef}
        modules={[Pagination]} effect="fade"
        loop={true}
        slidesPerView={1}
        pagination={{
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        }}
        onInit={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        <SwiperSlide>
          <Slide>
            <Img src="/place1.webp" />
            <Content>
              <Title>«Ритуальная служба» г. Красноярск приветствует вас.</Title>
              <Subtitle>Одна из первых частных ритуальных организаций города Красноярск</Subtitle>
              <Texts>
                <Text>
                  Мы знаем, что смерть близкого — это большая потеря,
                  которая отнимает почти все силы, как моральные, так
                  и физические. Именно поэтому к выбору агенства
                  предоставляющего ритуальные услуги стоит отнестись с
                  ответственностью.
                </Text>
                <Text>
                  Мы многие годы профессионально занимаемся
                  организацией похорон в соответствии с общепринятыми
                  ритуальными традициями и установленными государством
                  требованиями и всегда готовы помочь в тяжелый
                  период, когда вы сталкиваетесь с уходом близких и
                  дорогих вам людей.

                </Text>
              </Texts>
            </Content>
          </Slide>
        </SwiperSlide>
        <SwiperSlide>
          <Slide>
            <Img src="/place1.webp" />
            <Content>
              <Title>«Ритуальная служба» г. Красноярск приветствует вас.</Title>
              <Subtitle>Одна из первых частных ритуальных организаций города Красноярск</Subtitle>
              <Texts>
                <Text>
                  Мы знаем, что смерть близкого — это большая потеря,
                  которая отнимает почти все силы, как моральные, так
                  и физические. Именно поэтому к выбору агенства
                  предоставляющего ритуальные услуги стоит отнестись с
                  ответственностью.
                </Text>
                <Text>
                  Мы многие годы профессионально занимаемся
                  организацией похорон в соответствии с общепринятыми
                  ритуальными традициями и установленными государством
                  требованиями и всегда готовы помочь в тяжелый
                  период, когда вы сталкиваетесь с уходом близких и
                  дорогих вам людей.

                </Text>
              </Texts>
            </Content>
          </Slide>
        </SwiperSlide>
      </Swiper>
      <div className="swiper-pagination"></div>
      <Navigation>
        <button arealabel="Листать слайдер влево" onClick={() => swiperRef.current.slidePrev()}><img src="/welcome-prev.png" alt="Листать слайдер влево" /></button>
        <button arealabel="Листать слайдер вправо" onClick={() => swiperRef.current.slideNext()}><img src="/welcome-next.png" alt="Листать слайдер вправо" /></button>
      </Navigation>
    </TabsSliderWrapper >
  )
}
